import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

/**
 * @deprecated Legacy icon.
 * */
function PinterestIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 438.529 438.529">
      <path d="M409.141 109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.751 9.801 259.065 0 219.281 0 179.5 0 142.812 9.801 109.22 29.407c-33.597 19.604-60.194 46.201-79.8 79.796C9.809 142.8.008 179.485.008 219.267c0 44.35 12.085 84.611 36.258 120.767 24.172 36.172 55.863 62.912 95.073 80.232-.762-20.365.476-37.209 3.709-50.532l28.267-119.348c-4.76-9.329-7.139-20.93-7.139-34.831 0-16.175 4.089-29.689 12.275-40.541 8.186-10.85 18.177-16.274 29.979-16.274 9.514 0 16.841 3.14 21.982 9.42 5.142 6.283 7.705 14.181 7.705 23.7 0 5.896-1.099 13.084-3.289 21.554-2.188 8.471-5.041 18.273-8.562 29.409-3.521 11.132-6.045 20.036-7.566 26.692-2.663 11.608-.476 21.553 6.567 29.838 7.042 8.278 16.372 12.423 27.983 12.423 20.365 0 37.065-11.324 50.107-33.972 13.038-22.655 19.554-50.159 19.554-82.514 0-24.938-8.042-45.21-24.129-60.813-16.085-15.609-38.496-23.417-67.239-23.417-32.161 0-58.192 10.327-78.082 30.978-19.891 20.654-29.836 45.352-29.836 74.091 0 17.132 4.854 31.505 14.56 43.112 3.235 3.806 4.283 7.898 3.14 12.279-.381 1.143-1.141 3.997-2.284 8.562-1.138 4.565-1.903 7.522-2.281 8.851-1.521 6.091-5.14 7.994-10.85 5.708-14.654-6.085-25.791-16.652-33.402-31.689-7.614-15.037-11.422-32.456-11.422-52.246 0-12.753 2.047-25.505 6.14-38.256 4.089-12.756 10.468-25.078 19.126-36.975 8.663-11.9 19.036-22.417 31.123-31.549 12.082-9.135 26.787-16.462 44.108-21.982s35.972-8.28 55.959-8.28c27.032 0 51.295 5.995 72.8 17.986 21.512 11.992 37.925 27.502 49.252 46.537 11.327 19.036 16.987 39.403 16.987 61.101 0 28.549-4.948 54.243-14.842 77.086-9.896 22.839-23.887 40.778-41.973 53.813-18.083 13.042-38.637 19.561-61.675 19.561-11.607 0-22.456-2.714-32.548-8.135-10.085-5.427-17.034-11.847-20.839-19.273-8.566 33.685-13.706 53.77-15.42 60.24-3.616 13.508-11.038 29.119-22.27 46.819 20.367 6.091 41.112 9.13 62.24 9.13 39.781 0 76.47-9.801 110.062-29.41 33.595-19.602 60.192-46.199 79.794-79.791 19.606-33.599 29.407-70.287 29.407-110.065.01-39.778-9.777-76.468-29.376-110.06z"/>
    </SvgIcon>
  )
}
export default PinterestIcon
