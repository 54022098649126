import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'

import DestinationsCarousel from './DestinationsCarousel'
import { fetchPopularDestinations } from 'actions/DestinationAlertsActions'

interface Props {
  currentRegionName: string;
  popularDestinations: {
    domestic: App.PopularDestinationState;
    international: App.PopularDestinationState;
  };
}

function DestinationCarousels({ popularDestinations, currentRegionName }: Props) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPopularDestinations('domestic'))
    dispatch(fetchPopularDestinations('international'))
  }, [dispatch])

  const hasDomesticDestinations = popularDestinations.domestic.destinations?.length > 0
  const hasInternationalDestinations = popularDestinations.international.destinations?.length > 0

  if (!hasDomesticDestinations && !hasInternationalDestinations) {
    return null
  }

  return (
    <>
      {hasDomesticDestinations &&
        <DestinationsCarousel
          heading={`Popular hotel destinations in ${currentRegionName}`}
          destinations={popularDestinations.domestic.destinations}
        />
      }
      {hasInternationalDestinations &&
        <DestinationsCarousel
          heading="Popular hotel destinations internationally"
          destinations={popularDestinations.international.destinations}
        />
      }
    </>
  )
}

function mapStateToProps(state: App.State) {
  return ({
    popularDestinations: state.destination.popularDestinations,
    currentRegionName: state.geo.currentRegionName,
  })
}

export default connect(mapStateToProps)(DestinationCarousels)
