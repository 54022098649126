import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LayoutContainer from 'components/Common/LayoutContainer'
import DestinationTile from './DestinationTile'
import Heading from 'components/Luxkit/Typography/Heading'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'

const StyledLayoutContainer = styled(LayoutContainer)`
  text-align: left;
  > * + * {
    margin-top: ${rem(4)};
  }
`

interface Props {
  heading: string;
  destinations: Array<App.PopularDestination>,
}

function DestinationsCarousel({ heading, destinations }: Props) {
  return <StyledLayoutContainer>
    <Heading variant="heading3" as="h3">{heading}</Heading>
    <CardCarousel pageSize={5}>
      {destinations.map(destination => <DestinationTile destination={destination} key={destination.name}/>)}
    </CardCarousel>
  </StyledLayoutContainer>
}

export default DestinationsCarousel
