import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineRssIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M2.88 16.88a3 3 0 0 0 0 4.24 3 3 0 0 0 4.24 0 3 3 0 0 0-4.24-4.24Zm2.83 2.83a1.004 1.004 0 1 1-1.42-1.42 1.001 1.001 0 0 1 .71-.2958.9994.9994 0 0 1 .71.2958 1.001 1.001 0 0 1 .2958.71 1.0005 1.0005 0 0 1-.2958.71ZM5 12a1.0001 1.0001 0 0 0-.7071 1.7071 1 1 0 0 0 .707.2929 5 5 0 0 1 5 5A.9997.9997 0 0 0 11 20a1.0001 1.0001 0 0 0 1-1 6.9998 6.9998 0 0 0-7-7Zm0-4a1 1 0 0 0 0 2 8.9999 8.9999 0 0 1 9 9 .9997.9997 0 0 0 1 1 1.0001 1.0001 0 0 0 1-1 11.0802 11.0802 0 0 0-3.22-7.78A11.08 11.08 0 0 0 5 8Zm10.61.39A15.11 15.11 0 0 0 5 4a1 1 0 0 0 0 2 13.0001 13.0001 0 0 1 13 13 .9997.9997 0 0 0 1 1 1.0001 1.0001 0 0 0 1-1 15.1102 15.1102 0 0 0-4.39-10.61Z"/>
  </SvgIcon>
}

export default LineRssIcon
